<template>
  <div class="style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="4">
          <div class="title-header">
            Gestion des types sociétés
          </div>
        </v-col>
        <v-col cols="1">
          <v-progress-circular
            class="ml-2"
            v-if="loadingForFetch"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="7">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="getTypeSocieteError">
              <ul v-if="Array.isArray(getTypeSocieteError)" class="mb-0">
                <li v-for="(e, index) in getTypeSocieteError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getTypeSocieteError }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <div class="search-input">
            <search
              :value="searchValue"
              @changeSearchValue="changeSearchValue"
              hiddenContent="true"
            />
          </div>
          <v-tooltip bottom color="#311B92" v-if="checkPermission('GTSFLA')">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center"
                v-on="on"
                v-bind="attrs"
                @click.prevent="handleModalTypeSociete('add', 'show')"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter Type société</span>
          </v-tooltip>
        </div>
      </div>
      <div class="content-card-table">
        <v-data-table
          v-model="selectedTable"
          :headers="fields"
          :items="getTypeSociete"
          class="table-setting"
          hide-default-footer
          :calculate-widths="false"
          :fixed-header="true"
          :items-per-page="perPage"
          hide-action
          :disable-sort="true"
          :no-data-text="
            loadingForFetch
              ? 'Chargement en cours ...'
              : `Il n'y a aucun enregistrement à afficher`
          "
        >
          <!--TYPE-->
          <template v-slot:[`item.type_societe`]="{ item }">
            <td class="custom-cell">{{ item.type }}</td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td class="custom-cell">
              <div class="d-flex actions-style-type-societe">
                <div
                  class="bloc-icon-gestion mr-1"
                  v-if="checkPermission('GTSFLS')"
                  @click.prevent.stop="updateCurrentType(item)"
                  title="Modifier type societe"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </div>
                <div
                  v-if="checkPermission('GTSFLM')"
                  class="bloc-icon-gestion  mr-1"
                  @click.prevent.stop="deleteCurrentType(item)"
                  title="Supprimer type société"
                >
                  <font-awesome-icon icon="trash" />
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="footer-style-table">
      <div class="text-center pagination-table ">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify mr-2">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
    <!--MODAL ADD TYPE SOCIETE-->
    <v-dialog
      v-model="addTypeSociete"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter Type Société </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalTypeSociete('add', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Type société"
                  v-model="type"
                  :persistent-placeholder="true"
                  :rules="rules"
                  :error-messages="errorMessagesType"
                  validate-on-blur
                  class="msg-error"
                  ref="type"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </form>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleSubmitType"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalTypeSociete('add', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL UPDATE TYPE SOCIETE-->
    <v-dialog
      v-model="updateTypeSociete"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier Type Société </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalTypeSociete('update', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Type société"
                  v-model="type"
                  :persistent-placeholder="true"
                  :rules="rules"
                  :error-messages="errorMessagesType"
                  required
                  class="msg-error"
                  ref="type"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </form>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleUpdatetypeSte"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalTypeSociete('update', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DELETE TYPE SOCIETE-->
    <v-dialog
      v-model="deleteTypeSociete"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card v-if="typeToDelete && typeToDelete != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer Type Société </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalTypeSociete('delete', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div class="mt-4 ml-3 text-center">
            <p>
              Êtes-vous sur de vouloir supprimer ce type
              <span class="bold-text">
                {{
                  typeToDelete && typeToDelete.type ? typeToDelete.type : ''
                }}</span
              >
              ?
            </p>
          </div>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleDeleteType"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalTypeSociete('delete', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      selectedTable: [],
      errorMessagesType: [],
      page: 1,
      rules: [v => !!v || 'Type société est obligatoire.'],
      addTypeSociete: false,
      updateTypeSociete: false,
      deleteTypeSociete: false,
      perPage: 50,
      perPageList: [
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      fields: [
        {
          value: 'type_societe',
          text: 'Type Societe',
          width: '100% !important'
        },
        {
          value: 'actions',
          text: 'Actions'
        }
      ],
      typeToDelete: null,
      typeToUpdate: null,
      type: null,
      error: null,
      loadingForFetch: false,
      loading: false,
      searchValue: null
    };
  },
  methods: {
    ...mapActions([
      'fetchAllTypeSociete',
      'addNewTypeSte',
      'deletetypeSte',
      'updateTypeSte'
    ]),
    updateCurrentType(data) {
      this.type = data.type;
      this.typeToUpdate = data;
      this.handleModalTypeSociete('update', 'show');
    },
    handleModalTypeSociete(method, action) {
      if (method == 'add') {
        if (action == 'show') {
          this.addTypeSociete = true;
        } else {
          this.resetModal();
          this.$refs.type.errorBucket = [];
          this.addTypeSociete = false;
        }
      }
      if (method == 'update') {
        if (action == 'show') {
          this.updateTypeSociete = true;
        } else {
          this.updateTypeSociete = false;
          this.$refs.type.errorBucket = [];
          this.resetModal();
        }
      }
      if (method == 'delete') {
        if (action == 'show') {
          this.deleteTypeSociete = true;
        } else {
          this.deleteTypeSociete = false;
          this.resetModal();
        }
      }
    },
    deleteCurrentType(data) {
      this.typeToDelete = data;
      this.handleModalTypeSociete('delete', 'show');
    },
    async handleDeleteType() {
      this.loading = true;
      const response = await this.deletetypeSte(this.typeToDelete);
      if (response) {
        this.loading = false;
        this.handleModalTypeSociete('delete', 'hide');
      } else {
        this.loading = false;
      }
    },
    async handleSubmitType() {
      this.loading = true;
      if (this.$refs.type.validate() == false) {
        this.errorMessagesType = this.$refs.type.errorBucket;
        this.loading = false;
      } else {
        const response = await this.addNewTypeSte({
          type: this.type
        });
        if (response) {
          this.loading = false;
          this.handleModalTypeSociete('add', 'hide');
        } else {
          this.loading = false;
          this.error = this.getTypeSocieteError;
        }
      }
    },
    resetModal() {
      this.errorMessagesType = [];
      this.type = null;
      this.error = null;
      this.loading = false;
    },
    async handleUpdatetypeSte() {
      this.loading = true;
      if (this.$refs.type.validate() == false) {
        this.errorMessagesType = this.$refs.type.errorBucket;
        this.loading = false;
      } else {
        const response = await this.updateTypeSte({
          typeSte: this.typeToUpdate,
          type: this.type
        });
        if (response) {
          this.loading = false;
          this.handleModalTypeSociete('update', 'hide');
        } else {
          this.loading = false;
          this.error = this.getTypeSocieteError;
        }
      }
    },
    async handleFilter() {
      this.loadingForFetch = true;
      await this.fetchAllTypeSociete({
        per_page: this.perPage,
        page: this.page,
        type: this.searchValue
      });
      this.loadingForFetch = false;
    },
    async pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    async changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    }
  },
  computed: {
    ...mapGetters([
      'getTypeSociete',
      'getTypeSocieteLoading',
      'getTypeSocieteError',
      'getTypeSocieteDetailsLoading',
      'getTotalRowsTypeSte',
      'checkPermission'
    ]),
    totalPages() {
      if (this.getTotalRowsTypeSte) {
        return Math.ceil(this.getTotalRowsTypeSte / this.perPage);
      }
      return this.getTotalRowsTypeSte;
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue')
  },
  async mounted() {
    this.handleFilter();
  }
};
</script>
<style lang="scss" scoped>
.actions-style-type-societe {
  position: relative;
  margin: auto;
  justify-content: center;
}
</style>
